
import { defineComponent, reactive, computed, toRefs } from "vue";
import router from "@/router/index";
import { useStore } from 'vuex';
import { listJobSeekerView, listHunterJob, createChatInfo, totalJob,listJobApply } from '@/util/apis'
import dfpop from "@/views/components/dfPop.vue"
export default defineComponent({
  name: "Home",
  data() {
    return {
      currentPage: 1,
      pagesize: 10,
      psArr: [10, 20, 30],
      tableData: [] as any,
      totalCount: 0,
      rightNavIndex: 0 as any,
      TOWLIST: [] as any,
      totalCountTwo: 0,
      currentPageTwo: 1,
      pagesizeTwo: 10,
      jobList: [] as any,
      ClickIndex: -1,
      jobObj: {} as any,
      SchatCount:0
    };
  },
  created() {
    this.getList();
    this.getLookM();
    totalJob({}).then(res => {
      this.jobObj = res.data;
    })
    listJobApply({
        page: 1,
        pageSize: 50,
        matchState: "0",
        state: 0
      }).then(res => {
        this.SchatCount = res.data.resumeList.length
      })
  },
  components: {
    dfpop
  },
  methods: {
    handleSizeChange(size: number) {
      this.pagesize = size;
    },

    handleCurrentChange(currentPage: number) {
      this.currentPage = currentPage;
    },
    handleSizeChangeTwo(size: number) {
      this.pagesizeTwo = size;
      this.getLookM();
    },
    handleCurrentChangeTwo(currentPage: number) {
      this.currentPageTwo = currentPage;
      this.getLookM();
    },
    goRP() {
      router.push({ path: "/releasePosition/index" });
    },
    goLook() {
      router.push({ path: "/look/index" });
    },
    TOWLICLCIK(i: number) {
      this.TOWLIST[i].SHOWLOADING = true;
      setTimeout(() => {
        this.TOWLIST[i].SHOWLOADING = false;
      }, 1500)
    },
    getList() {
      listHunterJob({
        page: 1,
        pageSize: 1000,
        state: '1',
      }).then(res => {
        res.data.list.forEach((element: any) => {
          element.cusCity = element.ext.cityList.map((item: any) => item.remarks).join('、')
        });
        this.totalCount = res.data.total;
        this.tableData = res.data.list;
      })
    },
    getLookM() {
      listJobSeekerView({
        page: this.currentPage,
        pageSize: this.pagesize
      }).then(res => {
        res.data.resumeList.forEach((element: any) => {
          element.SHOWLOADING = false;
        });
        this.TOWLIST = res.data.resumeList;
        this.totalCountTwo = res.data.totalCount;
      })
    },
    showChat(ClickIndex: any) {
      (this.$refs.dfpopref as any).show(this.tableData);
      this.ClickIndex = ClickIndex;
    },
    popconfirm(i: any) {
      let ITEM: any = this.TOWLIST[this.ClickIndex]
      createChatInfo({
        seekerUserId: ITEM.userId, jobId: this.tableData[i].id
      }).then(res => {
        if (res.code == 0) {
          if (res.data && res.data.id) {
            router.push({ path: "/news/index", name: 'news', params: { id: res.data.id } });
            return
          }
          router.push({ path: "/news/index" });
        }
      })
    },
    goJob(e: any) {
      router.push({ path: "/jobDetails/index", name: 'jobDetails', params: { id: e.id } });
    },
    goDetail(i: any) {
      router.push({ path: "/resume/index", name: 'resume', params: { id: this.TOWLIST[i].resumeId } });
    },
    goJoD(type: any) {
      if (type == 1) {
        router.push({ path: "/look/index", name: 'look' ,params: {cusMenu:'2-1'} });
      }
      if (type == 2) {
        router.push({ path: "/position/index", name: 'position' });
      }
      if (type == 3) {
        router.push({ path: "/look/index", name: 'look',params: {cusMenu:'1-0'} });
      }
    }
  },
  setup: () => {
    const store = useStore();
    const state = reactive({
      userInfo: computed(() => store.state.user.userInfo),
      companyInfo: computed(() => store.state.user.companyInfo),
    })
    return {
      ...toRefs(state)
    }
  },
});
