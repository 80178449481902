<template>
  <div class="home-wrap flex">
    <el-row style="width: 1200px">
      <el-col :span="8">
        <div class="home-left shrink_0" style="border-radius: 2px">
          <div class="flex align-center justify-between">
            <div class="flex align-center">
              <el-image class="h-l-logo" :src="userInfo.photo" />
              <!-- <div class="h-l-logo"></div> -->
              <div style="margin-left: 22px;" class="flex flex-direction align-start">
                <div class="c_595959 text-18" style="font-weight: bold">欢迎回来{{ userInfo.name ? '，' + userInfo.name : ''
                }}</div>
                <div class="text-14 c_595959" style="margin-top: 8px;">{{ companyInfo.name || '' }}</div>
              </div>

            </div>
            <!-- <div class="h-l-box">已认证</div> -->
          </div>
          <div class="w100 bs flex justify-center" style="margin-top: 24px">
            <el-button type="primary" style="width: 120px;" @click="goRP">发职位</el-button>
            <el-button type="primary" style="width: 120px;margin-left: 42px;" @click="goLook">找人才</el-button>
          </div>
          <div class="text-16 c_595959" style="text-align: left; line-height: 40px;margin-top: 42px;">
            <div v-if="userInfo.emai">简历接收邮箱：{{ userInfo.email }}</div>
            <div v-if="userInfo.mobile">手机号：{{ userInfo.mobile }}</div>
            <div v-if="companyInfo.description">公司简介：{{ companyInfo.description }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="flex-sub flex flex-direction shrink_0" style="margin-left: 20px; padding-bottom: 20px">
          <div class="w100 bs flex align-center justify-center h-right-top">
            <div class="h-right-nav text-18">
              沟通中 <span class="c_3291F8 text-24 cur-p" @click="goJoD(1)">{{ SchatCount || '0' }}</span>
            </div>
            <div class="h-right-nav-line"></div>
            <div class="h-right-nav text-18">
              招聘中 <span class="c_3291F8 text-24 cur-p" @click="goJoD(2)">{{ jobObj.jobCount || '0' }}</span>
            </div>
            <div class="h-right-nav-line"></div>
            <div class="h-right-nav text-18">
              我的人才 <span class="c_3291F8 text-24 cur-p" @click="goJoD(3)">{{ jobObj.applyCount || '0' }}</span>
            </div>
          </div>
          <div class="h-right-con w100 bs" style="min-height:400px;">
            <div class="h-right-con-title flex align-center">
              <div class="text-16 cur-p" :class="rightNavIndex == 0 ? 'c_3291F8' : ''" @click="rightNavIndex = 0">
                今日应聘
              </div>
              <div style="margin: 0 30px; color: #ccc" class="text-24">|</div>
              <div class="text-16 cur-p" :class="rightNavIndex == 1 ? 'c_3291F8' : ''" @click="rightNavIndex = 1">
                看过我
              </div>
            </div>
            <div class="bs h-right-con-one" v-show="rightNavIndex == 0">
              <el-table :data="tableData.slice((currentPage - 1) * pagesize, currentPage * pagesize)"
                style="width: 100%" header-cell-class-name="text-16 c_101010" cell-class-name="text-14 c_595959 cur-p">
                <el-table-column prop="name" align="center" label="职位名" show-overflow-tooltip>
                  <template #default="scope">
                    <div @click="goJob(scope.row)">{{ scope.row.name }}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="cusCity" align="center" label="地址" />
                <el-table-column prop="ext.applyCount" align="center" width="100" label="应聘量" />
                <el-table-column prop="ext.applyCount" align="center" width="100" label="今日应聘量" />
                <el-table-column prop="ext.waitBackCount" align="center" width="100" label="待反馈" />
                <el-table-column prop="updateDate" align="center" label="更新时间" show-overflow-tooltip />
              </el-table>
            </div>
            <div class="bs h-right-con-two" v-show="rightNavIndex == 1">
              <div class="w100 bs h-two-li cur-p flex" v-for="(item, index) in TOWLIST" :key="index"
                @click="goDetail(index)">
                <div class="h-two-li-icon shrink_0">
                  <el-image class="h-two-li-icon" :src="item.photo ? item.photo : ''" fit="contain" />
                </div>
                <div class="flex-sub" style="margin-left: 16px">
                  <el-row justify="space-between">
                    <el-col :span="20">
                      <div class="flex align-center text-14" style="line-height: 26px">
                        <div class="c_3291F8">{{ item.name }}</div>
                        <div class="etreqmg1">|</div>
                        {{ item.gender }}
                        <div class="etreqmg1">|</div>
                        {{ item.age }}岁
                        <div class="etreqmg1">|</div>
                        {{ item.liveCity }}
                        <div class="etreqmg1">|</div>
                        {{ item.experience }}年
                      </div>
                      <div class="text-14 c_text_c" style="line-height: 26px">
                        <div class="flex align-center" v-if="item.education">
                          <span>最高学历：</span>{{ item.education.startTime }} - {{ item.education.endTime }}
                          <div class="etreqmg1">|</div>
                          <span>{{ item.education.name }}</span>
                          <div class="etreqmg1">|</div>
                          {{ item.education.ext.education }}
                        </div>
                        <div class="flex" v-if="item.workList && item.workList.length > 0">
                          <div class="shrink_0">最近工作：</div>
                          <div class="flex-sub">
                            <div class="flex align-center" v-for="(wItem, wIndex) in item.workList" :key="wIndex">
                              {{ wItem.startTime }} - {{ wItem.endTime }}
                              <div class="etreqmg1">|</div>
                              <span>{{ wItem.company }}</span>
                              <div class="etreqmg1">|</div>
                              {{ wItem.job }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="flex flex-direction text-14 align-center">
                        <el-button type="primary" :loading="item.SHOWLOADING" @click.stop="showChat(index)">和他聊聊
                        </el-button>
                        <!-- <div style="margin:10px 0;">看过的职位：</div>
                        <div class="c_3291F8">技术开发</div> -->
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
            <div class=" w100 bs flex justify-end" style="margin:10px 0; padding: 0 10px;">
              <div v-show="rightNavIndex == 0">
                <el-pagination lazy layout="total ,prev,pager,next,sizes,jumper" :total="totalCount"
                  @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                  :page-sizes="psArr" style="font-size: 20px">
                </el-pagination>
              </div>
              <div v-show="rightNavIndex == 1">
                <el-pagination lazy layout="total ,prev,pager,next,sizes,jumper" :total="totalCountTwo"
                  @size-change="handleSizeChangeTwo" @current-change="handleCurrentChangeTwo"
                  :current-page="currentPageTwo" :page-sizes="psArr" style="font-size: 20px">
                </el-pagination>

              </div>
            </div>
          </div>


        </div>
      </el-col>
    </el-row>
    <dfpop ref="dfpopref" @confirm="popconfirm" />
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, computed, toRefs } from "vue";
import router from "@/router/index";
import { useStore } from 'vuex';
import { listJobSeekerView, listHunterJob, createChatInfo, totalJob,listJobApply } from '@/util/apis'
import dfpop from "@/views/components/dfPop.vue"
export default defineComponent({
  name: "Home",
  data() {
    return {
      currentPage: 1,
      pagesize: 10,
      psArr: [10, 20, 30],
      tableData: [] as any,
      totalCount: 0,
      rightNavIndex: 0 as any,
      TOWLIST: [] as any,
      totalCountTwo: 0,
      currentPageTwo: 1,
      pagesizeTwo: 10,
      jobList: [] as any,
      ClickIndex: -1,
      jobObj: {} as any,
      SchatCount:0
    };
  },
  created() {
    this.getList();
    this.getLookM();
    totalJob({}).then(res => {
      this.jobObj = res.data;
    })
    listJobApply({
        page: 1,
        pageSize: 50,
        matchState: "0",
        state: 0
      }).then(res => {
        this.SchatCount = res.data.resumeList.length
      })
  },
  components: {
    dfpop
  },
  methods: {
    handleSizeChange(size: number) {
      this.pagesize = size;
    },

    handleCurrentChange(currentPage: number) {
      this.currentPage = currentPage;
    },
    handleSizeChangeTwo(size: number) {
      this.pagesizeTwo = size;
      this.getLookM();
    },
    handleCurrentChangeTwo(currentPage: number) {
      this.currentPageTwo = currentPage;
      this.getLookM();
    },
    goRP() {
      router.push({ path: "/releasePosition/index" });
    },
    goLook() {
      router.push({ path: "/look/index" });
    },
    TOWLICLCIK(i: number) {
      this.TOWLIST[i].SHOWLOADING = true;
      setTimeout(() => {
        this.TOWLIST[i].SHOWLOADING = false;
      }, 1500)
    },
    getList() {
      listHunterJob({
        page: 1,
        pageSize: 1000,
        state: '1',
      }).then(res => {
        res.data.list.forEach((element: any) => {
          element.cusCity = element.ext.cityList.map((item: any) => item.remarks).join('、')
        });
        this.totalCount = res.data.total;
        this.tableData = res.data.list;
      })
    },
    getLookM() {
      listJobSeekerView({
        page: this.currentPage,
        pageSize: this.pagesize
      }).then(res => {
        res.data.resumeList.forEach((element: any) => {
          element.SHOWLOADING = false;
        });
        this.TOWLIST = res.data.resumeList;
        this.totalCountTwo = res.data.totalCount;
      })
    },
    showChat(ClickIndex: any) {
      (this.$refs.dfpopref as any).show(this.tableData);
      this.ClickIndex = ClickIndex;
    },
    popconfirm(i: any) {
      let ITEM: any = this.TOWLIST[this.ClickIndex]
      createChatInfo({
        seekerUserId: ITEM.userId, jobId: this.tableData[i].id
      }).then(res => {
        if (res.code == 0) {
          if (res.data && res.data.id) {
            router.push({ path: "/news/index", name: 'news', params: { id: res.data.id } });
            return
          }
          router.push({ path: "/news/index" });
        }
      })
    },
    goJob(e: any) {
      router.push({ path: "/jobDetails/index", name: 'jobDetails', params: { id: e.id } });
    },
    goDetail(i: any) {
      router.push({ path: "/resume/index", name: 'resume', params: { id: this.TOWLIST[i].resumeId } });
    },
    goJoD(type: any) {
      if (type == 1) {
        router.push({ path: "/look/index", name: 'look' ,params: {cusMenu:'2-1'} });
      }
      if (type == 2) {
        router.push({ path: "/position/index", name: 'position' });
      }
      if (type == 3) {
        router.push({ path: "/look/index", name: 'look',params: {cusMenu:'1-0'} });
      }
    }
  },
  setup: () => {
    const store = useStore();
    const state = reactive({
      userInfo: computed(() => store.state.user.userInfo),
      companyInfo: computed(() => store.state.user.companyInfo),
    })
    return {
      ...toRefs(state)
    }
  },
});
</script>
<style scoped >
@import "./index.css";
</style>